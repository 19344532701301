import React from "react";
import { IoClose } from "react-icons/io5";
import ReactModal from "react-modal";

interface DialogProps {
  id?: string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  closable?: boolean;
  children: React.ReactNode;
  maxWidth?: number;
}

const Dialog: React.FC<DialogProps> = ({
  id,
  maxWidth = 500,
  title,
  isOpen,
  onClose,
  closable = false,
  children,
}) => {
  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      shouldCloseOnOverlayClick={false}
      // TODO: Need to fix this later to improve accessibility
      ariaHideApp={false}
      closeTimeoutMS={200}
      style={{
        overlay: {
          backgroundColor: "#00004488",
          backdropFilter: "blur(4px)",
          zIndex: 1001,
        },
        content: {
          maxWidth,
          margin: "auto",
          height: "min-content",
          maxHeight: "90vh",
          padding: "32px",
          borderRadius: "16px",
        },
      }}
    >
      <div className="flex flex-row gap-4 justify-end">
        {closable && (
          <button onClick={onClose}>
            <IoClose size={30} />
          </button>
        )}
      </div>
      {title && <h2 className="text-center my-4">{title}</h2>}
      {children}
    </ReactModal>
  );
};

export default Dialog;
