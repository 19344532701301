/* eslint-disable @next/next/no-img-element */
import React from "react";

interface Props {
  id: string;
  label: string;
  price: number;
  icon: string;
}

const BlockchainOption: React.FC<Props> = ({ id, label, price, icon }) => (
  <div className="flex flex-row items-center gap-2" key={`mint-option-${id}`}>
    <img src={icon} alt={label} className="w-4 h-4 object-contain" />
    <p className="flex-1">{label}</p>
    <p>
      {new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(price || 0)}
    </p>
  </div>
);

export default BlockchainOption;
