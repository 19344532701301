/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import { useWallet as useSolWallet } from "@solana/wallet-adapter-react";
import { MintStrategy, useMintStrategy } from "hooks/useMintStrategy";
import { useNftStore } from "hooks/useNftStore";
import { useAccount } from "wagmi";
import ConnectWalletButton from "components/wallets/ConnectButton";
import AlertModal from "components/modals/AlertModal";
import {
  PayMintStatus,
  type PayStationStatusInfo,
  openPaystationWidget,
  preparePayStationBody,
} from "utils/paystation";
import { MintInformation, orderApi, settingApi } from "api";
import { useRouter } from "next/router";
import { cryptoFiatExchange } from "utils/coingecko";
import { useAuth } from "hooks/useAuth";
import {
  BlockchainCompatibility,
  BlockchainSymbol,
  getBlockchainById,
  isWalletCompatible,
} from "constants/blockchains";
import { rerouteTo } from "utils/reroute";
import { getPricingInfo } from "utils/pricing";
import { loadBabkaLoginButton } from "utils/login";
import {
  babkaLoginButtonId,
  babkaLoginClientId,
  babkaLoginState,
} from "constants/babka";

const enum PaymentStatus {
  IDLE,
  GENERATING_TOKEN,
  PENDING_PAYMENT,
  SUCCESS,
  ERROR,
}

const checkIsAllParamPresent = (
  merchant_id: string | string[] | undefined,
  project_id: string | string[] | undefined,
  item_sku: string | string[] | undefined,
  item_id: string | string[] | undefined
) =>
  merchant_id &&
  merchant_id !== "undefined" &&
  project_id &&
  project_id !== "undefined" &&
  item_sku &&
  item_sku !== "undefined" &&
  item_id &&
  item_id !== "undefined";

const checkIsFreeItem = (code: number) =>
  code === PayMintStatus["uvi-claimed"] ||
  code === PayMintStatus["minting-success"];

const checkIfWithoutMinting = (
  selectedMintStrategy: MintStrategy | undefined
) =>
  selectedMintStrategy &&
  ["mintLater", "transferLater"].includes(selectedMintStrategy.id);

/**
 * @description if the below condition true, need to call payment/completed api
 * !["mintLater", "transferLater"].includes(selectedMintStrategy?.id || "")
 * && shippingMethod === "mint") -> "Buy now mint now"
 * !["mintLater", "transferLater"].includes(selectedMintStrategy?.id || "")
 * && shippingMethod === "transfer") -> "Buy now transfer now"
 * selectedMintStrategy?.id === "mintLater" && shippingMethod === "mint" -> "Buy now mint later"
 */
const checkIsMintShipment = (
  selectedMintStrategy: MintStrategy | undefined,
  shippingMethod: string | undefined
) =>
  (!["mintLater", "transferLater"].includes(selectedMintStrategy?.id || "") &&
    shippingMethod === "mint") ||
  (!["mintLater", "transferLater"].includes(selectedMintStrategy?.id || "") &&
    shippingMethod === "transfer") ||
  (selectedMintStrategy?.id === "mintLater" && shippingMethod === "mint");

const getMintInfo = ({
  hasMintingProcess,
  wallet_id,
  priceInfo,
  hasFee,
  selectedMintStrategy,
  item_id,
  theExchange,
}: {
  hasMintingProcess: boolean;
  wallet_id: string | undefined;
  priceInfo: Record<string, any> | undefined;
  hasFee: boolean;
  selectedMintStrategy: MintStrategy;
  item_id: string | string[] | undefined;
  theExchange: Record<string, any>;
}): MintInformation | undefined => {
  return hasMintingProcess && wallet_id
    ? {
        amount: hasFee ? priceInfo?.shipment ?? 0 : 0,
        blockchain: selectedMintStrategy.id,
        wallet_id,
        fiat: "USD",
        item_id: Number(item_id),
        crypto_fiat_rate: theExchange[selectedMintStrategy.label],
      }
    : undefined;
};

const PurchaseAction: React.FC = () => {
  const { itemInfo } = useNftStore();
  const router = useRouter();
  const { isTokenExpired, isAuthenticated } = useAuth();
  const { selectedMintStrategy, shippingMethod } = useMintStrategy();
  const { connected, publicKey } = useSolWallet();
  const { isConnected, address } = useAccount();
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
    PaymentStatus.IDLE
  );
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<
    string | undefined
  >();
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const [isButtonRender, setIsButtonRender] = useState<boolean>(false);
  const [isRendered, setIsRendered] = useState<boolean>(false);

  const priceInfo =
    itemInfo &&
    selectedMintStrategy &&
    getPricingInfo(
      itemInfo.is_free,
      selectedMintStrategy.hasFee,
      Number(itemInfo.price.amount),
      selectedMintStrategy.price,
      itemInfo.price.currency
    );

  const isWithoutMinting = checkIfWithoutMinting(selectedMintStrategy);

  const hasMintingProcess = !["mintLater", "transferLater"].includes(
    selectedMintStrategy?.id || ""
  );

  const isMintShipment = checkIsMintShipment(
    selectedMintStrategy,
    shippingMethod
  );

  const notValidInfo = !itemInfo || !selectedMintStrategy;

  const wallet_id = publicKey?.toBase58() || address;

  const notIsWalletConnected = hasMintingProcess && !wallet_id;

  // Payment sequence here
  const isWalletConnected = connected || isConnected;

  useEffect(() => {
    if ([PaymentStatus.ERROR].includes(paymentStatus)) {
      setTimeout(() => {
        setPaymentStatus(PaymentStatus.IDLE);
        setPaymentErrorMessage(undefined);
      }, 3000);
    }
  }, [paymentStatus]);

  const handlePayment = async () => {
    try {
      if (isTokenExpired()) {
        throw new Error("Token not exist");
      }
      // ? STEP 1: CONSTRUCT PAYMENT PAYLOAD
      setPaymentStatus(PaymentStatus.GENERATING_TOKEN);

      if (notValidInfo) {
        throw new Error("No item information");
      }

      if (notIsWalletConnected) {
        throw new Error("Wallet not connected");
      }

      const { item_id, merchant_id, project_id, item_sku } = router.query;
      const theExchange = await cryptoFiatExchange("USD");
      const paymentSetting = await settingApi.getPaymentSetting({
        project_id: Number(project_id),
      });

      const mintInfo: MintInformation | undefined = getMintInfo({
        hasMintingProcess,
        wallet_id,
        priceInfo,
        hasFee: !paymentSetting.is_paying_fees,
        selectedMintStrategy,
        item_id,
        theExchange,
      });

      const payStationBody = await preparePayStationBody({
        itemInfo: { ...itemInfo, item_id: item_id as string },
        mintInfo,
        config: {
          merchant_id: Number(merchant_id),
          project_id: Number(project_id),
        },
        hasFee: selectedMintStrategy.hasFee,
      });

      // ? STEP 2: GENERATE PAYMENT TOKEN
      orderApi
        .getPaymentToken(payStationBody)
        .then((res) => {
          if (res.code === PayMintStatus["shipping-method-not-allowed"]) {
            setShowAlertModal(true);
            setAlertMessage("Buy now, mint later option is forbidden");
            return;
          }

          if (checkIsFreeItem(res.code)) {
            setPaymentStatus(PaymentStatus.SUCCESS);
            return;
          }

          // ? STEP 3: OPEN PAYSTATION
          setPaymentStatus(PaymentStatus.IDLE);
          setOpenPayment(true);
          openPaystationWidget(res.token, {
            "status-done": (_: never, data: PayStationStatusInfo) => {
              setPaymentStatus(PaymentStatus.SUCCESS);
              if (isMintShipment) {
                if (hasMintingProcess) {
                  setShowAlertModal(true);
                  setAlertMessage(
                    "You will be redirected to Babka inventory to view your purchased item. Do not close the window."
                  );
                }
                const payload = {
                  order_id: res.order_id,
                  paystation_invoice_id: data?.paymentInfo?.invoice,
                };
                orderApi
                  .postPaymentCompleted(payload)
                  .then(() => onMintPendingModalClose());
              }
            },
          });
        })
        .catch(() => {
          location.href = `${location.protocol}//${location.host}?merchant_id=${merchant_id}&project_id=${project_id}&item_sku=${item_sku}&item_id=${item_id}`;
        });
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const handleErrorMessage = (error: unknown) => {
    setPaymentStatus(PaymentStatus.ERROR);
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (error instanceof Error) {
      if (["Token not exist", "Token invalid"].includes(error.message)) {
        setPaymentErrorMessage("Please log in to proceed");
      }
    }
  };

  useEffect(() => {
    const { merchant_id, project_id, item_sku, item_id } = router.query;
    const isAllParamsPresent = checkIsAllParamPresent(
      merchant_id,
      project_id,
      item_sku,
      item_id
    );

    async function loadLoginButton() {
      if (!isRendered) {
        const babkaLoginButton = loadBabkaLoginButton({
          babkaLoginButtonId,
          babkaLoginClientId,
          babkaLoginState,
          locale: "en",
          redirect_uri: `${location.protocol}//${location.host}?merchant_id=${merchant_id}&project_id=${project_id}&item_sku=${item_sku}&item_id=${item_id}`,
          audience: [`${location.protocol}//${location.host}`],
        });
        /* @ts-ignore */
        if (babkaLoginButton?.iframe) {
          /* @ts-ignore */
          babkaLoginButton.iframe.height = "50px";
          /* @ts-ignore */
          babkaLoginButton.iframe.width = "400px";
        }
      }

      setIsRendered(true);
    }

    if (router.isReady) {
      if (!isButtonRender) {
        setIsButtonRender(true);
      }

      if (isButtonRender && isAllParamsPresent) {
        loadLoginButton();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isButtonRender, router]);

  useEffect(() => {
    if (!openPayment && paymentStatus === PaymentStatus.SUCCESS) {
      rerouteTo("Babka NFT");
    }
  }, [openPayment, paymentStatus]);

  const checkWalletCompatible = () => {
    if (
      !selectedMintStrategy ||
      ["mintLater", "transferLater"].includes(selectedMintStrategy.id)
    ) {
      return false;
    }
    const blockchainInfo = getBlockchainById(selectedMintStrategy.id);

    if (!blockchainInfo) {
      return false;
    }

    return isWalletCompatible(selectedMintStrategy?.id as BlockchainSymbol, [
      isConnected
        ? BlockchainCompatibility.EVM
        : connected
        ? BlockchainCompatibility.SOL
        : null,
    ]);
  };

  const onMintPendingModalClose = () => {
    setShowAlertModal(false);
    setOpenPayment(false);
  };

  const walletCompatible = checkWalletCompatible();

  const isPaymentDisabled =
    paymentStatus !== PaymentStatus.IDLE ||
    (hasMintingProcess && !walletCompatible);

  const renderActionButton = () => {
    if (itemInfo?.is_sales_exist) {
      return (
        <button
          className="flex flex-row gap-2 font-bold px-4 py-2
    data-[error=true]:!from-ps4-text-alert data-[error=true]:!to-ps4-text-alert data-[error=true]:!text-ps4-alert
    bg-gradient-to-r from-[#AB7EE7] via-[#FF7998] to-[#FF99AC]
    rounded ml-auto mt-4 hover:brightness-125 transition-all duration-300
    disabled:from-slate-400 disabled:to-slate-500 w-full justify-center align-center"
          disabled={true}
          data-error={true}
        >
          {itemInfo.is_free ? "Item was claimed" : "Item was sold"}
        </button>
      );
    }

    if (!itemInfo?.limits.per_item?.available) {
      return (
        <button
          className="flex flex-row gap-2 font-bold px-4 py-2
   data-[error=true]:!from-ps4-text-alert data-[error=true]:!to-ps4-text-alert data-[error=true]:!text-ps4-alert
   bg-gradient-to-r from-[#AB7EE7] via-[#FF7998] to-[#FF99AC]
   rounded ml-auto mt-4 hover:brightness-125 transition-all duration-300
   disabled:from-slate-400 disabled:to-slate-500 w-full justify-center align-center"
          disabled={true}
          data-error={true}
        >
          Item reserved by another user
        </button>
      );
    }

    return (
      <>
        {isAuthenticated && (
          <>
            <div
              className="data-[hide=true]:hidden"
              data-hide={isWithoutMinting || isWalletConnected}
            >
              <ConnectWalletButton className="my-4 w-full" />
            </div>
            <div
              className="data-[hide=true]:hidden"
              data-hide={!isWithoutMinting && !isWalletConnected}
            >
              {!isWithoutMinting && !walletCompatible && (
                <p className="p-4 bg-ps4-alert text-ps4-text-alert rounded my-2">
                  Select a blockchain compatible with your connected crypto
                  wallet
                </p>
              )}
              <button
                className="flex flex-row gap-2 font-bold px-4 py-2
      data-[error=true]:!from-ps4-text-alert data-[error=true]:!to-ps4-text-alert data-[error=true]:!text-ps4-alert
      bg-gradient-to-r from-[#AB7EE7] via-[#FF7998] to-[#FF99AC]
      rounded ml-auto mt-4 hover:brightness-125 transition-all duration-300
      disabled:from-slate-400 disabled:to-slate-500 w-full justify-center align-center"
                onClick={handlePayment}
                disabled={isPaymentDisabled}
                data-error={!!paymentErrorMessage}
              >
                {[
                  PaymentStatus.GENERATING_TOKEN,
                  PaymentStatus.PENDING_PAYMENT,
                ].includes(paymentStatus) && (
                  <>
                    <div className="w-4 h-4 border-4 border-t-transparent rounded-full border-white animate-spin" />
                    <p>Opening your order payment</p>
                  </>
                )}
                {paymentStatus === PaymentStatus.IDLE && (
                  <>
                    <img src="/images/icon/double-star.png" alt="Double Star" />
                    <p className="pr-12 text-xl">
                      {priceInfo?.subtotal === 0 ? "Claim" : "Pay"}
                    </p>
                  </>
                )}
                {paymentStatus === PaymentStatus.SUCCESS && (
                  <>
                    <p>Success!</p>
                  </>
                )}
                {paymentStatus === PaymentStatus.ERROR && (
                  <>
                    <p>{paymentErrorMessage}</p>
                  </>
                )}
              </button>
            </div>
          </>
        )}
        <div
          className="flex justify-center my-4 data-[hide=true]:hidden"
          data-hide={isAuthenticated}
        >
          <div id={babkaLoginButtonId} />
        </div>
        <AlertModal
          key={`${showAlertModal}`}
          isOpen={showAlertModal}
          message={alertMessage}
          onClose={() => setShowAlertModal(false)}
        />
      </>
    );
  };

  return <>{renderActionButton()}</>;
};

export default PurchaseAction;
