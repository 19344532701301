/* eslint-disable @next/next/no-img-element */
import Description from "components/checkout/Description";
import Title from "components/checkout/Title";
import Meta from "components/Meta";
import { useMintStrategy } from "hooks/useMintStrategy";
import { StoreApiStatus, useNftStore } from "hooks/useNftStore";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import TransactionInfo from "components/checkout/TransactionInfo";
import { useAuth } from "hooks/useAuth";

export interface Attribute {
  external_id: string;
  name: Description;
  values: Value[];
}

export interface Description {
  en: string;
}

export interface Value {
  external_id: string;
  value: Description;
}

export interface InventoryOptions {
  consumable: boolean;
  expiration_period: null;
}

export interface Price {
  amount: number;
  currency: string;
  is_default: boolean;
  is_enabled: boolean;
}

const StoreAssetDetails: React.FC = () => {
  const { itemInfo, getItem, storeApiStatus } = useNftStore();
  const { changeShippingMethod } = useMintStrategy();
  const router = useRouter();
  const { saveToken } = useAuth();

  const fetchItemInfo = () => {
    getItem({
      projectId: Number(router.query.project_id),
      itemSku: router.query.item_sku as string,
    });
  };

  useEffect(() => {
    if (!location.href.includes("?")) {
      return location.replace("https://babka.com");
    }

    if (router.isReady) {
      const queryParam = router.query;
      const isAllParamsPresent =
        queryParam.merchant_id &&
        queryParam.merchant_id !== "undefined" &&
        queryParam.project_id &&
        queryParam.project_id !== "undefined" &&
        queryParam.item_sku &&
        queryParam.item_sku !== "undefined" &&
        queryParam.item_id &&
        queryParam.item_id !== "undefined";

      const isTokenPresent = queryParam.token && queryParam.token !== undefined;

      if (isTokenPresent) {
        saveToken(router.query.token as string);
        router.replace(
          `/?merchant_id=${router.query.merchant_id}&project_id=${router.query.project_id}&item_sku=${router.query.item_sku}&item_id=${router.query.item_id}`
        );

        return;
      }

      if (isAllParamsPresent) {
        fetchItemInfo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (!itemInfo) {
      return;
    }

    if (!itemInfo.is_minted) {
      changeShippingMethod("mint");
    } else {
      changeShippingMethod("transfer");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfo]);

  return (
    <div
      className="bg-babka-brand min-h-screen flex flex-col items-stretch bg-babka-nft bg-cover bg-repeat-y"
      id="background"
    >
      <Meta title={itemInfo?.name || "Item"} />

      {process.env.APP_ENV !== "prod" && (
        <div className="sticky top-0 left-0 w-full bg-ps4-warning text-ps4-text-warning px-8 py-4 inline-flex text-center">
          <p className="w-full">
            ⚠️ This is {process.env.APP_ENV} environment.
          </p>
        </div>
      )}

      <main className="flex justify-center items-center flex-1 mx-4 my-8">
        {storeApiStatus === StoreApiStatus.LOADING && (
          <div className="w-16 h-16 border-8 border-t-transparent rounded-full border-white animate-spin" />
        )}
        {storeApiStatus === StoreApiStatus.SUCCESS && itemInfo && (
          <div
            id="nft-card"
            className="w-full max-w-screen-xl px-20 py-16 flex flex-col md:flex-row bg-[#0C0330] rounded-3xl gap-12"
          >
            <section id="nft-image" className="flex-1">
              <img
                src={itemInfo.image_url}
                alt={itemInfo.sku}
                className="relative h-full w-full object-contain object-top"
              />
            </section>
            <section
              id="nft-details"
              className="flex-1 flex flex-col gap-8 text-white"
            >
              <Title name={itemInfo.name} />
              <TransactionInfo />
              <Description
                description={itemInfo.description}
                groups={itemInfo.groups}
              />
            </section>
          </div>
        )}
      </main>
    </div>
  );
};

export default StoreAssetDetails;
