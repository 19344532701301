import Head from "next/head";
import React from "react";

interface MetaProps {
  title?: string;
  description?: string;
  imageURL?: string;
  twitterCard?: "summary" | "summary_large_card";
}

const defaultMeta = {
  title: "👾 Xsolla | NFT Checkout",
  description: "A GameFi platform for NFT checkout",
  imageURL: "https://cdn.xsolla.net/xsolla-com-v6/file-3P5vV1PE.jpg",
  twitterCard: "summary_large_card",
};

const Meta: React.FC<MetaProps> = ({
  title = defaultMeta.title,
  description = defaultMeta.description,
  imageURL = defaultMeta.imageURL,
}) => {
  const env = process.env.APP_ENV;
  if (env !== "production") {
    if (title === defaultMeta.title) {
      console.warn("You are currently using default title for the page.");
    }

    if (description === defaultMeta.description) {
      console.warn(
        "You are currently using the default description for the page."
      );
    }

    if (imageURL === defaultMeta.imageURL) {
      console.warn("You are currently using default image for the page");
    }
  }

  return (
    <Head>
      {/* Basic configuration */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <link rel="icon" href="/favicon.ico" />

      {/* Basic information */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageURL} />
      <meta property="og:site_name" content="Xsolla NFT Checkout" />

      {/* Twitter */}
      <meta
        property="twitter:card"
        content="summary_large_image"
        key="twcard"
      />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageURL} />
      <meta property="twitter:site" content="@Xsolla" />
    </Head>
  );
};

export default Meta;
