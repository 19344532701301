export type Wallet = {
  id: string;
  name: string;
  icon: string;
  disabled?: boolean;
};

export const evmWallets: Wallet[] = [
  {
    id: "metamask",
    name: "MetaMask",
    icon: "/images/logo/metamask.svg",
  },
  {
    id: "walletconnect",
    name: "WalletConnect",
    icon: "/images/logo/walletconnect.svg",
  },
];
