import NewDropdown from "components/bases/NewDropdown";
import { useNftStore } from "hooks/useNftStore";
import { useMintStrategy } from "hooks/useMintStrategy";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import BlockchainOption from "./BlockchainOption";

const SelectBlockchain: React.FC = () => {
  const { itemInfo } = useNftStore();
  const [ready, setReady] = useState(false);
  const {
    mintStrategies,
    selectedMintStrategy,
    loadEnabledBlockchain,
    selectMintStrategy,
    shippingMethod,
  } = useMintStrategy();
  const router = useRouter();

  const mappedMintStrategy = useMemo(() => {
    return mintStrategies.map((strategy) => {
      return {
        id: strategy.id,
        value: strategy.id,
        component: (
          <BlockchainOption
            id={strategy.id}
            icon={strategy.icon}
            label={strategy.label}
            price={strategy.hasFee ? strategy.price : 0}
          />
        ),
      };
    });
  }, [mintStrategies]);

  useEffect(() => {
    if (router.isReady && ready && shippingMethod && itemInfo) {
      loadEnabledBlockchain(
        Number(router.query.project_id),
        itemInfo.blockchain
      );

      return;
    }
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, ready, shippingMethod, itemInfo]);

  return (
    <div id="nft-blockchain-selection" className="mb-8">
      <p className="uppercase text-[#FFFFFFB4] mb-4">
        Blockchain for&nbsp;
        {shippingMethod === "mint" && "minting"}
        {shippingMethod === "transfer" && "transfering"}
      </p>
      {shippingMethod === "mint" && (
        <NewDropdown
          selectedIndex={mintStrategies.findIndex(
            (strategy) => strategy.id === selectedMintStrategy?.id
          )}
          itemComponents={mappedMintStrategy}
          onChange={(newIndex) => selectMintStrategy(mintStrategies[newIndex])}
        />
      )}
      {shippingMethod === "transfer" && selectedMintStrategy && (
        <div className="flex flex-row items-center gap-2">
          <img
            src={selectedMintStrategy?.icon}
            alt={selectedMintStrategy?.label}
            className="w-4 h-4 object-contain"
          />
          <p className="flex-1">{selectedMintStrategy?.label}</p>
          <p>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(selectedMintStrategy?.price || 0)}
          </p>
        </div>
      )}
    </div>
  );
};

export default SelectBlockchain;
