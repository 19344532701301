/**
 * Steps on Pay Station Checkout
 * 1. Get token
 * https://developers.xsolla.com/api/pay-station/operation/create-token/
 *
 * 2. Open Payment UI
 * https://developers.xsolla.com/doc/pay-station/integration-guide/open-payment-ui/
 *
 * 3. Record return
 *
 * 4. Webhook to monitor progress
 */
import {
  MintInformation,
  NFTPayStationProps,
  PayStationPurchase,
  PayStationSettings,
  PayStationUser,
  StoreItem,
  StoreUVI,
  getToken,
} from "../api";
import { jwtDecoded } from "./jwtDecoded";
import { getPricingInfo } from "./pricing";

export enum PayMintStatus {
  'uvi-paid' = 200,
  'uvi-minting-fee-paid' = 201,
  'minting-started' = 202,
  'minting-success' = 203,
  'uvi-claimed' = 204,
  'uvi-payment-failed' = 400,
  'minting-payment-failed' = 401,
  'minting-failed' = 402,
  'shipping-method-not-allowed' = 403
};

export type GeneratePaystationTokenProps = {
  items?: (StoreItem & { item_id: string })[];
  merchantId: number;
  projectId: number;
  total?: number;
  currency: string;
  mintInfo?: MintInformation;
  callbackUrl?: string;
};

export type PayStationStatus =
  | "init"
  | "open"
  | "load"
  | "close"
  | "status"
  | "status-invoice"
  | "status-delivering"
  | "status-done"
  | "status-troubled"
  | "user-country";

// eslint-disable-next-line @typescript-eslint/ban-types
export type PayStationEvents = Record<PayStationStatus, Function>;

export type PayStationStatusInfo = {
  paymentInfo: {
    discount: any;
    email: string;
    invoice: number;
    status: string;
    userId: string;
    virtualCurrencyAmount: any;
  }
}

export type PayStationStatusEvent = Record<string, any>;

export const openPaystationWidget = (
  token: string,
  events?: Partial<PayStationEvents>
) => {
  const options = {
    access_token: token,
    sandbox: process.env.PAYSTATION_SANDBOX === "true" ? true : undefined,
  };
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "//cdn.xsolla.net/embed/paystation/1.2.7/widget.min.js";
  s.addEventListener(
    "load",
    () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const widget = XPayStationWidget;
      widget.init(options);
      widget.open();
      if (events) {
        Object.entries(events).forEach(([psEvent, psCallback]) => {
          widget.on(psEvent, psCallback);
        });
      }
    },
    false
  );
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(s);
};

type PreparePayStationBodyProps = {
  itemInfo: StoreUVI & { item_id: string };
  mintInfo?: MintInformation;
  config: {
    callbackUrl?: string;
    currency?: string;
    project_id: number;
    merchant_id: number;
  };
  hasFee: boolean;
};

export const preparePayStationBody = async ({
  itemInfo,
  mintInfo,
  config,
  hasFee
}: PreparePayStationBodyProps): Promise<NFTPayStationProps> => {
  const currency = config?.currency || "USD";

  // Block 1: User
  const token = getToken();
  if (!token) {
    throw new Error("Token not exist");
  }

  const parsedToken = jwtDecoded(token);
  if (!parsedToken) {
    throw new Error("Token invalid");
  }

  const user: PayStationUser = {
    id: {
      value: parsedToken.sub,
    },
    email: {
      value: parsedToken.email,
    },
  };

  // Block 2: Settings
  const settings: PayStationSettings = {
    currency,
    project_id: config.project_id,
    return_url: `https://babka.com/nft`,
    redirect_policy: {
      manual_redirection_action: "postmessage",
    },
    ui: {
      theme: "default_dark",
    },
    mode: process.env.PAYSTATION_SANDBOX === "true" ? "sandbox" : undefined,
  };

  // Block 3: Purchase
  const priceInfo = getPricingInfo(
    itemInfo.is_free,
    hasFee,
    Number(itemInfo.price.amount),
    mintInfo?.amount || 0,
    currency
  );

  const purchase: PayStationPurchase = {
    checkout: {
      amount: priceInfo.subtotal,
      currency,
    },
    description: {
      value: "Purchase of Unique Virtual Item and NFT",
      items: [
        {
          name: itemInfo.name,
          description: `${itemInfo.item_id}:${itemInfo.sku}`,
          image_url: itemInfo.image_url,
          price: {
            amount: priceInfo.item.toString(),
          },
          quantity: 1,
        },
      ],
    },
  };

  const invoice = {
    user,
    settings,
    purchase,
  };

  return {
    invoice,
    merchant_id: config.merchant_id,
    mint_order: mintInfo,
  };
};
