import axios from "axios";
import { Blockchain } from "../configurations/blockchains";

export const cryptoFiatExchange = async (fiat: string) => {
  const exchange = await axios.get(
    `https://api.coingecko.com/api/v3/simple/price`,
    {
      params: {
        ids: "matic-network,solana,ethereum,binancecoin",
        vs_currencies: fiat,
      },
    }
  );

  return {
    [Blockchain.ETH]: exchange.data["ethereum"].usd,
    [Blockchain.MATIC]: exchange.data["matic-network"].usd,
    [Blockchain.SOL]: exchange.data["solana"].usd,
    [Blockchain.BSC]: exchange.data["binancecoin"].usd
  };
};
