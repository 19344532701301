import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

type ProviderName = "metamask" | "walletconnect" | string;

export const getProvider = (providerName: ProviderName) => {
  switch (providerName) {
    case "metamask":
      return new MetaMaskConnector();
    case "walletconnect":
      return new WalletConnectConnector({
        options: {
          qrcode: true,
        },
      });
    default:
      return new InjectedConnector();
  }
};
