import React, { useState } from "react";
import ConnectWallet from "../modals/ConnectWallet";
import { useWallet as useSolWallet } from "@solana/wallet-adapter-react";
import { useAccount, useDisconnect } from "wagmi";

const enum WalletModal {
  OPEN = 1,
  CLOSE = 0,
}

type ConnectWalletButtonProps = {
  className?: string;
};

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  className,
}) => {
  const [isWalletModal, setIsWalletModal] = useState<WalletModal>(
    WalletModal.CLOSE
  );
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { connected, disconnect } = useSolWallet();

  const handleWalletModal = (operation: WalletModal) => {
    setIsWalletModal(() => operation);
  };

  const disconnectEVMWallet = async () => {
    if (isConnected) {
      await disconnectAsync();
    }
  };

  const handleDisconnect = () => {
    setIsWalletModal(WalletModal.CLOSE);
    disconnectEVMWallet();
    if (connected) {
      disconnect();
    }
  };

  if (isConnected || connected) {
    return (
      <>
        <button
          type="button"
          className={`babka-btn babka-btn-outline-primary ${className}`}
          onClick={handleDisconnect}
        >
          Disconnect Wallet
        </button>
      </>
    );
  } else {
    return (
      <>
        <button
          type="button"
          className={`babka-btn babka-btn-solid-primary ${className}`}
          onClick={() => handleWalletModal(WalletModal.OPEN)}
        >
          Connect Wallet
        </button>
        <ConnectWallet
          isOpen={!!isWalletModal}
          onClose={() => handleWalletModal(WalletModal.CLOSE)}
        />
      </>
    );
  }
};

export default ConnectWalletButton;
