import { StoreUVI } from "api";
import React from "react";

type Props = Pick<StoreUVI, "description" | "groups">;

const Description: React.FC<Props> = ({ description, groups }) => (
  <div id="nft-description" className="flex flex-col gap-4 text-white">
    <div className="flex flex-row gap-4 items-center">
      <p className="uppercase text-sm text-[#FFFFFFB4]">Category</p>
      <hr className="h-px flex-1 border-slate-700" />
    </div>
    <div className="flex flex-row gap-2 text-center items-center text-sm">
      {groups.map((group) => (
        <div
          className="flex items-center justify-center px-4 py-1 border border-[#C1F53D] rounded-full"
          key={`group-${group.external_id}`}
        >
          <p>{group.name.en}</p>
        </div>
      ))}
    </div>
    <div>
      <p>Artist: -</p>
      <p>{description}</p>
    </div>
  </div>
);

export default Description;
