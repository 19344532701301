import React from "react";
import BaseModal from "./BaseModal";

interface AlertModalProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  message,
  onClose,
}) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} maxWidth={450}>
      <div className="my-4">
        <p className="text-center">{message}</p>
      </div>
    </BaseModal>
  );
};

export default AlertModal;
