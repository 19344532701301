import { useMintStrategy } from "hooks/useMintStrategy";
import { useNftStore } from "hooks/useNftStore";
import React from "react";
import { getPricingInfo } from "utils/pricing";

const PriceInfo: React.FC = () => {
  const { itemInfo } = useNftStore();
  const { selectedMintStrategy, shippingMethod } = useMintStrategy();
  const defaultCurrency = "USD";

  const priceInfo =
    itemInfo &&
    selectedMintStrategy &&
    getPricingInfo(
      itemInfo.is_free,
      selectedMintStrategy.hasFee,
      Number(itemInfo.price.amount),
      selectedMintStrategy.price,
      itemInfo.price.currency
    );

  return (
    <div id="nft-price-info" className="flex flex-col gap-2">
      <div className="flex flex-row justify-between gap-8">
        <p>Price</p>
        <p>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: priceInfo?.currency || defaultCurrency,
            currencyDisplay: "code",
          }).format(priceInfo?.item || 0)}
        </p>
      </div>
      {!["mintLater", "transferLater"].includes(
        selectedMintStrategy?.id || ""
      ) &&
        selectedMintStrategy?.hasFee && (
          <div className="flex flex-row justify-between gap-8">
            <p>
              {shippingMethod === "mint" && "Minting"}
              {shippingMethod === "transfer" && "Transfer"} Fee
            </p>
            <p>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: priceInfo?.currency || defaultCurrency,
                currencyDisplay: "code",
              }).format(priceInfo?.shipment || 0)}
            </p>
          </div>
        )}
      <div className="flex flex-row justify-between gap-8">
        <p className="font-bold">Subtotal</p>
        <p className="font-bold text-3xl">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: priceInfo?.currency || defaultCurrency,
            currencyDisplay: "code",
          }).format(priceInfo?.subtotal || 0)}
        </p>
      </div>
    </div>
  );
};

export default PriceInfo;
