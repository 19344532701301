/* eslint-disable @next/next/no-img-element */
import React from "react";
import { useWallet as useSolWallet } from "@solana/wallet-adapter-react";
import { useAccount, useDisconnect } from "wagmi";
import { evmWallets } from "./walletInformation";

const WalletDisplay: React.FC = () => {
  const { isConnected: isEVMConnected, connector, address } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const {
    connected: isSOLConnected,
    publicKey,
    disconnect,
    wallet,
  } = useSolWallet();

  const disconnectWallet = async () => {
    try {
      if (!isEVMConnected && !isSOLConnected) {
        throw new Error("No wallet connected");
      }
      if (isEVMConnected) {
        await disconnectAsync();
      }
      if (isSOLConnected) {
        await disconnect();
      }
    } catch (error) {
      alert(error);
    }
  };

  const evmConnectorName = connector?.name;

  const connectedEVMWallet = evmWallets.find(
    (evmWallet) => evmWallet.name === evmConnectorName
  );

  if (!(isEVMConnected || isSOLConnected)) {
    return null;
  }

  return (
    <>
      <div className="flex flex-row gap-4 items-center">
        <p className="uppercase text-sm text-[#FFFFFFB4]">Wallet</p>
        <hr className="h-px flex-1 border-slate-700" />
      </div>
      <div className="flex flex-row gap-4 items-center my-2">
        {connectedEVMWallet && (
          <img
            src={connectedEVMWallet.icon}
            alt={evmConnectorName}
            width={32}
            height={32}
          />
        )}
        {wallet && (
          <img
            src={wallet.adapter.icon}
            alt={wallet.adapter.name}
            width={32}
            height={32}
          />
        )}
        <div className="flex-1 overflow-hidden">
          {evmConnectorName && (
            <p>
              <strong>{evmConnectorName}</strong>
            </p>
          )}
          {address && <p className="truncate max-w-[20ch]">{address}</p>}

          {wallet && (
            <p>
              <strong>{wallet.adapter.name}</strong>
            </p>
          )}
          {publicKey && <p>{publicKey.toBase58()}</p>}
        </div>
        <button
          className="border border-slate-700 rounded-xl p-3"
          onClick={disconnectWallet}
        >
          <img
            src="/images/icon/power-on.svg"
            alt="disconnect"
            className="text-white w-5 h-5"
          />
        </button>
      </div>
      <hr className="border-slate-700 my-4" />
    </>
  );
};

export default WalletDisplay;
