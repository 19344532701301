import { useWallet as useSolWallet } from "@solana/wallet-adapter-react";
import WalletDisplay from "components/wallets/WalletDisplay";
import { useMintStrategy } from "hooks/useMintStrategy";
import { useNftStore } from "hooks/useNftStore";
import React from "react";
import { useAccount } from "wagmi";
import PriceInfo from "./PriceInfo";
import PurchaseAction from "./PurchaseAction";
import SelectBlockchain from "./SelectBlockchain";

const TransactionInfo: React.FC = () => {
  const { itemInfo } = useNftStore();
  const { isConnected } = useAccount();
  const { connected } = useSolWallet();
  const { selectedMintStrategy } = useMintStrategy();

  const isSold = !itemInfo?.can_be_bought;

  const isDisplayWallet =
    (isConnected || connected) &&
    !["mintLater", "transferLater"].includes(selectedMintStrategy?.id || "");

  if (isSold) {
    return (
      <p className="p-4 bg-ps4-warning text-ps4-text-warning rounded">
        This item cannot be purchased. It is probably have been bought, or
        disabled by the merchant.
      </p>
    );
  }

  return (
    <div id="nft-transaction">
      <SelectBlockchain />
      {isDisplayWallet && <WalletDisplay />}
      <PriceInfo />
      <PurchaseAction />
    </div>
  );
};

export default TransactionInfo;
