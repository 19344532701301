import { StoreUVI } from "api";
import React from "react";

const Title: React.FC<Pick<StoreUVI, "name">> = ({ name }) => (
  <div id="nft-title">
    <h1 className="text-white text-4xl md:text-5xl lg:text-6xl flex-1 break-words min-w-[100px]">
      {name}
    </h1>
  </div>
);

export default Title;
