import { Widget } from "@xsolla/login-sdk";
import jwtDecode from "jwt-decode";
import { Button as BabkaLogin } from "@xsolla/accounts-babka-sdk";

const themeWidget = {
  width: "400px",
  height: "550px",
  scene: {
    color: "transparent",
  },
  tabs: {
    hide: true,
  },
};

const loadXsollaLoginScript = async (
  callbackUrl: string,
  payload?: string
): Promise<Widget> => {
  const currentUrl = window.location.origin;

  const widgetConfig = {
    projectId: process.env.LOGIN_PROJECT_ID || "0",
    preferredLocale: "en_US",
    theme: themeWidget,
    customLabels: new URL(
      `${currentUrl}/babkaLogin/login-locales.json`
    ).toString(),
    customStyle: new URL(`${currentUrl}/babkaLogin/login-style.css`).toString(),
    callbackUrl: callbackUrl,
    payload: payload,
    url: `${process.env.API_URL_LOGIN_WIDGET}/latest`,
  };

  const XsollaLogin = await import("@xsolla/login-sdk");

  return new XsollaLogin.Widget(widgetConfig);
};

export const openLoginWidget = async ({
  callbackUrl,
  payload,
}: {
  callbackUrl: string;
  payload?: string;
}): Promise<void> => {
  const theXl = await loadXsollaLoginScript(callbackUrl, payload);

  const loginWidget = document.createElement("div");
  loginWidget.setAttribute("id", "xl_auth");
  loginWidget.setAttribute(
    "style",
    `position: absolute; width: 100vw; height: 100vh;
      display: flex;align-items: center; justify-content: center;
      top: 0; background-color: #00000088;`
  );
  document.body.appendChild(loginWidget);
  theXl.on(theXl.events.Close, () => {
    loginWidget.remove();
    theXl.close();
  });

  theXl.mount("xl_auth");
};

type JWTDecoded = <TokenPayload = unknown>(
  token: string
) => TokenPayload | null;

export const jwtDecoded: JWTDecoded = (token: string) => {
  try {
    return jwtDecode(token);
  } catch {
    return null;
  }
};

type TokenPayload = {
  email: string;
  /** @description User ID is stored in `sub` and not `id` */
  sub: string;
  payload: string;
};

export const parseXsollaLoginToken = (token: string): TokenPayload => {
  const tokenPayload = jwtDecoded<TokenPayload>(token);

  if (!tokenPayload) {
    throw new Error("Fail to parse token");
  }

  return tokenPayload;
};

type BabkaLoginPayload = {
  babkaLoginButtonId: string;
  babkaLoginClientId: number;
  babkaLoginState: string;
  locale: string;
  redirect_uri: string;
  audience: string[];
};

export const loadBabkaLoginButton = (
  payload: BabkaLoginPayload
): BabkaLogin => {
  const {
    babkaLoginButtonId,
    babkaLoginClientId,
    babkaLoginState,
    locale,
    redirect_uri,
    audience,
  } = payload;
  const babkaLoginButton = new BabkaLogin({
    containerId: babkaLoginButtonId,
    locale,
    client_id: babkaLoginClientId,
    state: babkaLoginState,
    scope: ["email", "user_profile"],
    redirect_uri,
    audience,
    popup: false,
  });

  return babkaLoginButton;
};
