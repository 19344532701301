type RouteLocation = "Babka NFT";

export const rerouteTo = (routeLocation: RouteLocation) => {
  const env = process.env.APP_ENV;
  // let targetUrl = "/";

  if (routeLocation === "Babka NFT") {
    switch (env) {
      case "prod":
        location.href = "https://babka.com/nft";
        return;
      case "demo":
        location.href = "https://demo-nft.babka.com/nft";
        return;
      case "dev":
        location.href =
          "https://babka-frontend.gcp-k8s-b2c-login-stage.srv.local/nft";
        return;
      case "local":
      default:
        location.href = "http://localhost:3030/nft";
        return;
    }
  }

  // return location.assign(targetUrl);
};
