import React, { useEffect } from "react";
import { useConnect } from "wagmi";
import { evmWallets } from "./walletInformation";
import { useWallet as useSolWallet } from "@solana/wallet-adapter-react";
import { WalletName } from "@solana/wallet-adapter-base";
import { getProvider } from "utils/cryptoWallet/providers";

const Connectors: React.FC = () => {
  const { connectAsync } = useConnect();

  const connectToEVM = async (provider: string) => {
    try {
      await connectAsync({
        connector: getProvider(provider),
      });
    } catch (error) {
      alert(error);
    }
  };

  const { connect, select, wallets: solWallets, wallet } = useSolWallet();

  const handleSolConnection = async (walletName: WalletName<string>) => {
    try {
      select(walletName);
    } catch (error) {
      alert(error);
    }
  };

  const connectWallet = () => {
    connect();
  };

  useEffect(() => {
    if (wallet) {
      connectWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  return (
    <div className="flex flex-col items-stretch rounded-xl w-full max-w-md gap-2">
      {evmWallets.map(
        (wallet) =>
          !wallet.disabled && (
            <button
              className="rounded-xl px-8 py-4 flex flex-row items-center gap-4 border"
              onClick={() => connectToEVM(wallet.id)}
              key={`ethWallet-${wallet.id}`}
            >
              {wallet.icon ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                  src={wallet.icon}
                  alt={wallet.name}
                  className="w-8 h-8 object-contain"
                />
              ) : (
                <div className="w-8 h-8" />
              )}
              <p className="flex-1 text-center">{wallet.name}</p>
            </button>
          )
      )}
      {solWallets.map((wallet) => (
        <button
          className="rounded-xl px-8 py-4 flex flex-row items-center gap-4 border"
          key={`solWallet-${wallet.adapter.name}`}
          onClick={() => handleSolConnection(wallet.adapter.name)}
        >
          {wallet.adapter.icon ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src={wallet.adapter.icon}
              alt={wallet.adapter.name}
              className="w-8 h-8 object-contain"
            />
          ) : (
            <div className="w-8 h-8" />
          )}
          <p className="flex-1 text-center">{wallet.adapter.name}</p>
        </button>
      ))}
    </div>
  );
};

export default Connectors;
