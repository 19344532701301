export const getPricingInfo = (
  isFree: boolean = false,
  hasFee: boolean = true,
  itemPrice: number,
  shipPrice: number,
  currency: string
) => {
  const item = isFree ? 0 : itemPrice;
  const shipment = hasFee ? shipPrice : 0;
  const subtotal = item + shipment;

  return {
    item,
    shipment,
    subtotal,
    currency,
  };
};
