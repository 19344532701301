import React, { useEffect } from "react";
import BaseModal from "./BaseModal";
import Connectors from "../wallets/WalletConnector";
import { useWallet as useSolWallet } from "@solana/wallet-adapter-react";
import { useAccount } from "wagmi";

interface WalletSelectionProps {
  isOpen: boolean;
  onClose: () => void;
}

const WalletSelection: React.FC<WalletSelectionProps> = ({
  isOpen,
  onClose,
}) => {
  const { address } = useAccount();
  const { connected } = useSolWallet();

  useEffect(() => {
    if (address || connected) {
      onClose();
    }
  }, [address, connected, onClose]);

  const handleClose = () => {
    onClose();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      closable={true}
      title="Connect to Wallet"
      maxWidth={450}
    >
      <div className="flex flex-row items-stretch">
        <div className="flex flex-col flex-1 gap-2 items-center">
          <p className="w-full mb-2 text-center">
            Choose one of available wallet providers or create a new wallet.
          </p>
          <Connectors />
        </div>
      </div>
    </BaseModal>
  );
};

export default WalletSelection;
