/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  MenuButton,
  Menu as MenuInner,
  MenuItem as MenuItemInner,
} from "@szhsin/react-menu";
import { FaChevronDown } from "react-icons/fa";

const menuClassName = ({ state }: any) =>
  `absolute top-0 flex flex-col w-full z-50 border border-zinc-500 bg-white rounded
  ${state === "closed" && "hidden"}
  ${state === "opening" && "animate-fadeIn"}
  ${state === "closing" && "animate-fadeOut"}`;

const menuItemClassName = ({ hover, disabled }: any) =>
  `rounded-md px-4 py-4 focus:outline-none text-black transition-all duration-100
  ${hover && "bg-slate-200"}
  ${disabled && "text-gray-400"}
  `;

const menuThumbClassName = `
  flex flex-row border border-slate-700 rounded px-4 py-2 text-lg items-center gap-2 text-left w-full
`;

const Menu = (props: any) => (
  <MenuInner {...props} className="!relative" menuClassName={menuClassName} />
);

const MenuItem = (props: any) => (
  <MenuItemInner {...props} className={menuItemClassName} />
);

interface ItemComponent {
  id: string;
  value: string;
  component: JSX.Element;
  disabled?: boolean;
  hidden?: boolean;
}
interface DropdownProps {
  selectedIndex: number;
  itemComponents: ItemComponent[];
  onChange: (newIndex: number) => void;
  isLoading?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  selectedIndex,
  itemComponents,
  onChange,
  isLoading = false,
}) => {
  return (
    <Menu
      transition={true}
      menuButton={
        <MenuButton className={menuThumbClassName}>
          <div className="flex-1">
            {isLoading && "Loading..."}
            {!isLoading &&
              (selectedIndex >= 0
                ? itemComponents[selectedIndex].component
                : "Open Menu")}
          </div>
          <FaChevronDown />
        </MenuButton>
      }
    >
      {itemComponents?.map(
        (item, index) =>
          !item.hidden && (
            <MenuItem
              key={item.id}
              disabled={item.disabled}
              onClick={() => onChange(index)}
            >
              {item.component}
            </MenuItem>
          )
      )}
    </Menu>
  );
};

export default Dropdown;
